/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FiltersForm from "../Common/FiltersForm";
import CommonPagination from "../Common/CommonPagination";
import GetFundDonations from "../../Api/GetFundDonations";
import Scrollbars from "react-custom-scrollbars-2";
import { Form, Badge, Image, Collapse } from "react-bootstrap";
import MoreIcon from '../../assets/img/more_options.png';
import moment from "moment";
import { getDonationsList } from "../../redux/actions/donationAction";
import Avatar from "../Common/Avatar";
import GetCampaign from "../../Api/GetCampaign";
import { setStoreCampaignListOption } from "../../redux/actions/campaignAction";
import CommonModal from "../Common/CommonModal";
import Exportpopupmodel from "../Common/Exportpopupmodel";
import PreviewImage from "./PreviewImage";


const DonationsManagement = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken,selfInfo,is_mosaic,parent_compaign_Id } = useSelector((state) => state.personalReducers);
// is_mosaic==="yes"
    const { donationsList } = useSelector((state) => state.donationReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState("all")
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [FilterForm, setFilterForm] = useState({
        campaign:is_mosaic==="yes" ? parent_compaign_Id:"",
        start_date:"",
        end_date:"",
        res:"json",
    })
    // console.log("FilterForm start date",FilterForm?.start_date)
    // console.log("FilterForm end Date",FilterForm?.end_date)
    // page change
    const [loading, setLoading] = useState(false);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [ showModal2, setShowModal2 ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {},
        type:""
    });

    

    const currentFunction = (page) => {
        setCurrentPage(page);
        fetchData(page,userLimit,userType, FilterForm)
    };
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
        fetchData(1,userLimit,e.target.value, FilterForm);
    };
    const [abortController, setAbortController] = useState({});

    useEffect(() => {
        fetchData(1,userLimit,userType, FilterForm);
        return () => {
            if(abortController.abort){
                abortController.abort();
                setAbortController(new AbortController());
            }
        };
    }, [ userSearch, FilterForm?.campaign, FilterForm?.start_date, FilterForm?.end_date, paymentMethod]);
    // Get data from api
    const formatUserSearch = (input) => {
        const regex = /^\d+(\.\d+)?$/;
        if (regex.test(input)) {
          return parseFloat(input) * 100;
        }
        return input;
      };
    const fetchData = async (currentPage,userLimit,userType, FilterForm,type) => {
        const signal = abortController.signal;
        setCurrentPage(currentPage)

        const DonationsLists = await GetFundDonations(accessToken, formatUserSearch(userSearch), userType, userLimit, currentPage, {signal}, FilterForm, paymentMethod);
        if(DonationsLists?.status === 200) {
            if(FilterForm?.res == "json"){
                dispatch(getDonationsList(DonationsLists.data));
                setPaginationLength(DonationsLists.pagination);
            }else{
                if(DonationsLists?.data){
                    if(type==="avatar"){
                        downloadData(DonationsLists?.data, `DONATION_${moment().format("YYYY-MM-DD HHmmss a")}.zip`);
                    }else{
                        const ResultFile = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + DonationsLists?.data;
                        var link = document.createElement('a');
                        link.href = ResultFile;
                        const name_time = moment().format('YYYY-MM-DD-HHmmssA')
                        link.download = `donation-emails-${name_time}.xlsx`;
                        link.click();
                    }
                }
            }
            setLoading(false)
            // updateLoadingAndSuccessStates(portion, false, true);

        } else if(DonationsLists?.status === 403) {
            CommonLogout();
            if(FilterForm?.res == "json"){
                dispatch(getDonationsList([]));
                setPaginationLength({});
            }
            setLoading(false)
            // updateLoadingAndSuccessStates(portion, false, false);

        } else {
            if(FilterForm?.res == "json"){
                dispatch(getDonationsList([]));
                setPaginationLength({});
            }
            // updateLoadingAndSuccessStates(portion, false, false);
            setLoading(false)
        };
    };
    function downloadData(dataBase64, filename) {
        // Decode the Base64 data
        try {
            const decodedData = atob(dataBase64);
        
            // Convert the decoded data to a Uint8Array
            const byteArray = new Uint8Array(decodedData.length);
            for (let i = 0; i < decodedData.length; i++) {
            byteArray[i] = decodedData.charCodeAt(i);
            }
        
            // Create a Blob from the Uint8Array
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        
            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);
        
            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
        
            // Append the link to the document body
            document.body.appendChild(link);
        
            // Trigger a click event on the link
            link.click();
        
            // Remove the link from the document body
            document.body.removeChild(link);
        
            // Revoke the URL to release the resources
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error decoding Base64 data:', error);
        }
      
      }
      
    useEffect(()=>{
        getDonationModel(1, 5000, "all");
    },[])

    // Get data from api
    const getDonationModel = async (currentPage,userLimit,userType) => {
        const responce = await GetCampaign(accessToken, "", userType, userLimit, currentPage );
        if(responce?.status === 200) {
            dispatch(setStoreCampaignListOption(responce.data));
        } else {
            dispatch(setStoreCampaignListOption([]));
        };
    };
    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const callUserLimit = (e) =>{
        setUserLimit(e);
        fetchData(1, e,userType, FilterForm)
    }


    const numPortions = Math.ceil(userLimit / paginationLength.record_limit); // Calculate portions dynamically
    // const portionLimit = userLimit / numPortions; 
    // console.log("portionLimit",portionLimit)
    const [showDesign,setShowDesign] = useState(false)
    const [exportType, setExportType] = useState(null);

    const onExport = (type) => {
        setExportType(type)
        if (userLimit > 100) {
            setShowModal2({
                ...showModal2,
                open: !showModal2?.open,
                title: "Export  Avatars",
                description: `Do you really want to`,
                modalType: "not form",
                button: "Yes",
                category: "Donation Active",
                Data: {},
                type:type
            });
        } else {
            setLoading(true);
            fetchData(currentPage, userLimit, userType, { ...FilterForm, res: `export-${type}` },type);
            }
    };
    const handleExport = () => {
        setShowDesign(!showDesign);
    };
    

    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // Function to open the modal and set the selected image
    const openPopup = (image) => {
        setSelectedImage(image);
        setIsOpen(true);
        console.log("image",image)
    };

    const closePopup = () => {
        setIsOpen(false);
        setSelectedImage(null); // Optional: Clear the selected image when closing
    };


    

  
    


    const DonatationActivStatus = (donations) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            description: `Do you really want to ${donations?.is_active ? 'deactivate' : 'active'}`,
            modalType: "not form",
            button: "Yes",
            category: "Donation Active",
            Data: donations
        });
        // console.log("donations",donations)
    };

    return(<>
        <FiltersForm 
            filterType={"Donation(Name,email,amount)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={callUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            FilterForm={FilterForm}
            setFilterForm={setFilterForm}
            onExport={onExport}
            loading={loading}
            showDesign={showDesign}
            handleExport={handleExport}
            exportType={exportType}
            setShowDesign={setShowDesign}
        />
        <div className="tableContent" 
        >
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 87px)" : "calc(100% - 50px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>  Avatar</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Campaign Name</th>
                                <th>Rating</th>
                                
                            
                                {
                                    selfInfo?.permission==="mosaic-management,donation-management" ?  null:
<>

                                <th>Payment Method</th>
                                <th>Coupons</th>
                                <th>Amount</th>
                                {/* <th>Paid</th> */}
                                
</>
                                }
                                <th>Created At</th>

                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {donationsList && donationsList?.map((user, index) => {
                                {/* console.log("user?.rating",user?.rating) */}
                                return(<React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === user?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>
                                    {
                                         
                                        user?.user_avatar ? 
                                                        <td style={{
                                                            cursor:"pointer"
                                                        }}
                                                        onClick={() => openPopup(user?.user_avatar)}

                                                        >
                                                         <Avatar type={"user"} src={user?.user_avatar} title={user?.first_name}


                                                         /> 
                                                        </td>
                                                    :
                                                    <td>No Image Found </td>

                                                    
                                    }
                                        {/* <Avatar type={"user"} src={user?.user_avatar} title={user?.first_name}/> */}
                                    </td>
                                    <td>{user?.user_name}</td>
                                    <td>{user?.email}</td>
                                    <td>{user?.campaign_title?user?.campaign_title:"-"}</td>
                                    {/* <td>
                                    {user?.rating === true && <span className="badge bg-primary">YES</span>}
                                        {user?.rating === false && <span className="badge bg-danger">NO</span>}
                                        {user?.rating == null && <span className="">-</span>}

                                    </td> */}
                                    <td>{user?.rating ? user?.rating : "-"}</td>
                                    {
                                         selfInfo?.permission==="mosaic-management,donation-management" ?  null:
                                    <>

                                    <td>
                                        <span className="text-capitalize">{user?.payment_method}</span>
                                    </td>
                                    <td>{user?.coupon ? user?.coupon: "-"}</td>
                                    <td>${Number(user?.amount / 100)?.toFixed(2)}</td>
                                    {/* <td>
                                        {user?.is_paid === true && <span className="badge bg-primary">True</span>}
                                        {user?.is_paid === false && <span className="badge bg-danger">false</span>}
                                        {user?.is_paid == null && <span className="badge bg-secondary">-</span>}
                                    </td> */}
                                    </>     
                                    }
                                    <td>{moment(user.created_at).format('LL HH:mm A')}</td>

                                    <td>
                                    
                <Form className="switchbtn">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        checked={user?.is_active}
                        onChange={e => {}}
                        onClick={() => DonatationActivStatus(user)}
                    />
                    <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                </Form>
            </td>
                                </tr>
                                <Collapse in={activeTableDetails === user?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                        <td colSpan="8">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                    {
                                                        selfInfo?.permission==="mosaic-management,donation-management" ?null:
                                                        <>
                                                        <th style={{ width: "20%" }}>Transaction id</th>
                                                        <td>{user?.transaction_id}</td>
                                                        </>
                                                    }
                                                    </tr>
                                                    <tr>
                                                    
                                                    <th style={{ width: "20%" }}> Original Avatar</th>

                                                   
                                                    {
                                                        user?.o_user_avatar ? 
                                                        <td
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => openPopup(user?.o_user_avatar)}

                                                        >
                                                    <Avatar type={"user"} src={user?.o_user_avatar} title={user?.o_user_avatar}

              
                                                    /> 
                                                        </td>
                                                    :
                                                    <td>No Image Found </td>

                                                    }
                                                    </tr>
                                                    {/* <tr>
                                                        <th style={{ width: "20%" }}>Created At</th>
                                                        <td>{moment(user.created_at).format('LL HH:mm A')}</td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <th style={{ width: "20%" }}>Comment</th>
                                                        <td>
                                                            <Form className="switchbtn">
                                                                <Badge bg={user?.is_comment ? "primary" : "danger"}>{user?.is_comment ? "Yes" : "No"}</Badge>
                                                            </Form>
                                                        </td>
                                                    </tr> */}
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Anonymous</th>
                                                        <td>
                                                            <Form className="switchbtn">
                                                                <Badge bg={user?.is_anonymous ? "primary" : "danger"}>{user?.is_anonymous ? "Yes" : "No"}</Badge>
                                                            </Form>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                            })}
                            {donationsList && donationsList?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Donation Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(paginationLength?.total_records) > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={{...paginationLength, total_records: parseFloat(paginationLength.total_records), record_limit: parseFloat(paginationLength.record_limit),}}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModal showModal={showModal} setShowModal={setShowModal} />)}
        {showModal2?.open && (<Exportpopupmodel showModal2={showModal2} setShowModal2={setShowModal2}
        userLimit={userLimit}
        currentPage={currentPage}
        paginationLength={paginationLength}
        setCurrentPage={setCurrentPage}
        setPaginationLength={setPaginationLength}
        userType={userType}
        FilterForm={FilterForm}
        abortController={abortController}
        userSearch={userSearch}
        paymentMethod={paymentMethod}
        downloadData={downloadData}

         />)}
         {
            isOpen && <PreviewImage
selectedImage={selectedImage}
closePopup={closePopup}
openPopup={openPopup}
            />
         }

    </>);
}

export default DonationsManagement;
