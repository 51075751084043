/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form, Image, Button } from 'react-bootstrap';
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import { useSelector, useDispatch } from "react-redux";
import { AddCampaignList, SelectCampaignTab, UpdateCampaignX } from "../../redux/actions/campaignAction";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CAMPAIGN_MANAGEMENT_URL } from "../Shared/constant";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UploadFile from "../../Api/UploadFile";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import UploadFileModal from "../Common/UploadFileModal";
import AddCampaign from "../../Api/AddCampaign";
import EditCampaign from "../../Api/EditCampaign";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetCampaignDetails from "../../Api/GetCampaignDetails";
import CommonLoader from "../Common/CommonLoader";

const ViewCampaign = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { campaignListIdWise, CurrenUrlBase } = useSelector((state) => state.campaignReducers);
    const Location = useLocation()?.pathname;
    const GetSlug = useLocation()?.pathname.split('/')[3];
    const CurrentCampaign = campaignListIdWise[Location.split('/')[Location.split('/').length - 1]];
    const CurrenUrlBaseMains = "/" + Location.split('/')[1] + "/" + Location.split('/')[2];
    const [ ViewCampaignState, setViewCampaignState ] = useState({
        title: "",
        subtitle: "",
        description: "",
    });
    const [ AthleteImage, setAthleteImage ] = useState("");
    const [ AthleteYoutubevideo, setAthleteYoutubevideo ] = useState("");
    const [ AthleteDescription, setAthleteDescription ] = useState("");
    const [ AthleteBio, setAthleteBio ] = useState("");
    const [ AthleteTitle, setAthleteTitle ] = useState("");
    const [ AthleteSubtitle, setAthleteSubtitle ] = useState("");

    const [ CharityImage, setCharityImage ] = useState("");
    const [ CampingVideo, setCampingVideo ] = useState("");
    const [ CharityYoutubevideo, setCharityYoutubevideo ] = useState("");
    const [ CharityDescription, setCharityDescription ] = useState("");
    const [ CharityBio, setCharityBio ] = useState("");
    const [ CharityTitle, setCharityTitle ] = useState("");
    const [ ContributeTitle, setContributeTitle ] = useState("");
    const [ MinimumAmount, setMinimumAmount ] = useState("");
    const [ DefaultAmount, setDefaultAmount ] = useState(false);
    const [ CharitySubtitle, setCharitySubtitle ] = useState("");
    const { roomId } = useParams();
    // upload avatar state
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ UploadCharityFileLoader, setUploadCharityFileLoader ] = useState(false);
    const [ YouTubeLinks, setYouTubeLinks ] = useState([]);
    const [ Logo, setLogo ] = useState("");
    const [ CommunityImage, setCommunityImage ] = useState("");
    const [ CommunityTitle, setCommunityTitle ] = useState("");
    const [ CommunityDescription, setCommunityDescription ] = useState("");
    const [ CommunitySocial, setCommunitySocial ] = useState({
        youtube:"",
        twitter:"",
        instagram:"",
        facebook:"",
        mail:"",
    });
    const [errorCommunitySocial, setErrorCommunitySocial] = useState({
        youtube:"",
        twitter:"",
        instagram:"",
        facebook:"",
        mail:"",
    });

    const [AthleteButtonTitle, setAthleteButtonTitle] = useState("Mission");
    const [CharityButtonTitle, setCharityButtonTitle] = useState("Mission");
    const [ CommunityLogo, setCommunityLogo ] = useState([]);
    const [ SponsorsLogo, setSponsorsLogo ] = useState([]);
    const [ SocialLogo, setSocialLogo ] = useState([]);
    const [ SponsorsTitle, setSponsorsTitle ] = useState("");

    // upload video url
    const [ showFileModal, setShowFileModal ] = useState({
        open: false,
        title: "",
    });
    const [ LoaderStart, setLoaderStart ] = useState(false);
    const [DigitValue, setDigitValue] = useState('');


    // error state
    const [ errorCampaignTitle, setErrorCampaignTitle ] = useState();
    const [ errorDefaultAmount, setErrorDefaultAmount ] = useState("");
    const [ errorCampaignSubtitle, setErrorCampaignSubtitle ] = useState();
    const [ errorCampaignDescription, setErrorCampaignDescription ] = useState();
    const [ errorAthleteTitle, setErrorAthleteTitle ] = useState();
    const [ errorAthleteSubTitle, setErrorAthleteSubTitle ] = useState();
    const [ errorAthleteDescription, setErrorAthleteDescription ] = useState();
    const [ errorAthleteBio, setErrorAthleteBio ] = useState();
    const [ errorCharityTitle, setErrorCharityTitle ] = useState();
    const [ errorCharitySubTitle, setErrorCharitySubTitle ] = useState();
    const [ errorCharityDescription, setErrorCharityDescription ] = useState();
    const [ errorCharityBio, setErrorCharityBio ] = useState();
    const [ errorAvatar, setErrorAvatar ] = useState("");
    const [ errorAvatarCharity, setErrorAvatarCharity ] = useState("");
    const [errorCommunityImage, setErrorCommunityImage] = useState("");
    const [errorCommunityDescription, setErrorCommunityDescription] = useState("");
    const [errorCommunityTitle, setErrorCommunityTitle] = useState("");
    const [errorsequenceAmount,setErrorSequenceAmount] =  useState('')
    const [errorSponsorsLogo,setErrorSponsorsLogo] = useState('')
    const [errorSponsorsTitle, setErrorSponsorsTitle] = useState('')
    // editor code
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video"
    ];
    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link", "image", "video"],
          ["clean"]
        ]
    };

    // get charity details
    const fetchData = async () => {
        if(CurrenUrlBaseMains === "/view/campaign" || CurrenUrlBaseMains === "/edit/campaign" ) {
            setLoaderStart(true);
            const Campaignget = await GetCampaignDetails(accessToken, GetSlug);
            if(Campaignget) {   
                setTimeout(() => {
                    setLoaderStart(false);
                }, 200);
                setViewCampaignState({
                    ...ViewCampaignState, 
                    title: Campaignget?.data?.title,
                    subtitle: Campaignget?.data?.subtitle,
                    description: decodeURI(Campaignget?.data?.description),
                    is_active:Campaignget?.data?.is_active
                });
                setDigitValue(Campaignget?.data?.sequence_no)
                // console.log("data",data)
                const CureentAthleteValue = JSON.parse(Campaignget.data.athlete.replace("None", 'null')?.replaceAll(/'/g, '"'));

                const CureentCharityValue = JSON.parse(Campaignget.data.charity.replace("None", 'null').replace("False", 'false').replace("True", 'true')?.replaceAll(/'/g, '"'));
// console.log("CureentCharityValue",CureentCharityValue)
                setAthleteDescription(CureentAthleteValue?.description.replaceAll('&#34','"').replaceAll('&#39', "'"));
                setAthleteImage(CureentAthleteValue?.image);
                setAthleteYoutubevideo(CureentAthleteValue?.youtubevideo);
                setAthleteBio(CureentAthleteValue?.bio.replaceAll('&#34','"').replaceAll('&#39', "'"));
                setAthleteTitle(CureentAthleteValue?.title);
                setAthleteSubtitle(CureentAthleteValue?.subtitle);

                setCharityDescription(CureentCharityValue?.description.replaceAll('&#34','"').replaceAll('&#39', "'"));
                setCharityImage(CureentCharityValue?.image);
                setCharityYoutubevideo(CureentCharityValue?.youtubevideo);
                setCharityBio(CureentCharityValue?.bio.replaceAll('&#34','"').replaceAll('&#39', "'"));
                setCharityTitle(CureentCharityValue?.title);
                setCharitySubtitle(CureentCharityValue?.subtitle);
                
                if(CureentCharityValue?.Logo){
                    setLogo(CureentCharityValue?.Logo);
                }
                if(CureentCharityValue?.MinimumAmount){
                    setMinimumAmount(CureentCharityValue?.MinimumAmount);
                }
                if(CureentCharityValue?.DefaultAmount != undefined){
                    setDefaultAmount(CureentCharityValue?.DefaultAmount == "true"?true:false);
                }
                if(CureentCharityValue?.ContributeTitle){
                    setContributeTitle(CureentCharityValue?.ContributeTitle);
                }
                if(CureentCharityValue?.YouTubeLinks){
                    setYouTubeLinks(CureentCharityValue?.YouTubeLinks);
                }
                // console.log("CureentAthleteValue?.button_title", CureentAthleteValue?.button_title)
                if(CureentAthleteValue?.button_title){
                    setAthleteButtonTitle(CureentAthleteValue?.button_title)
                }
                if(CureentCharityValue?.button_title){
                    setCharityButtonTitle(CureentCharityValue?.button_title)
                }
                if(CureentCharityValue?.CommunityTitle){
                    setCommunityTitle(CureentCharityValue?.CommunityTitle);
                }
                if(CureentCharityValue?.campaign_video){
                    setCampingVideo(CureentCharityValue?.campaign_video);
                }
                if(CureentCharityValue?.SponsorsTitle){
                    setSponsorsTitle(CureentCharityValue?.SponsorsTitle);
                }
                if(CureentCharityValue?.SponsorsLogo){
                    setSponsorsLogo(CureentCharityValue?.SponsorsLogo);
                }
                if(CureentCharityValue?.SocialLogo){
                    setSocialLogo(CureentCharityValue?.SocialLogo);
                }
                if(CureentCharityValue?.CommunityDescription){
                    setCommunityDescription(CureentCharityValue?.CommunityDescription);
                }
                if(CureentCharityValue?.CommunityImage){
                    setCommunityImage(CureentCharityValue?.CommunityImage);
                }
                if(CureentCharityValue?.CommunityLogo){
                    setCommunityLogo(CureentCharityValue?.CommunityLogo);
                }
                if(CureentCharityValue?.CommunitySocial){
                    setCommunitySocial((data)=>({
                        ...data,
                        facebook:CureentCharityValue?.CommunitySocial?.facebook ? CureentCharityValue?.CommunitySocial?.facebook : "",
                        instagram:CureentCharityValue?.CommunitySocial?.instagram ? CureentCharityValue?.CommunitySocial?.instagram : "",
                        youtube:CureentCharityValue?.CommunitySocial?.youtube ? CureentCharityValue?.CommunitySocial?.youtube : "",
                        twitter:CureentCharityValue?.CommunitySocial?.twitter ? CureentCharityValue?.CommunitySocial?.twitter : "",
                        mail:CureentCharityValue?.CommunitySocial?.mail ? CureentCharityValue?.CommunitySocial?.mail : "",
                    }));
                }
            };
        }
    };

    useEffect(() => {
        if(CurrenUrlBase === "edit-campaign") {
            fetchData();
        };
    }, [ accessToken, CurrenUrlBase ]);

    // last key code check and remove value
    const ValueKeyPress = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setViewCampaignState({...ViewCampaignState, description: ""});
        };
    };
    const ValueKeyPressAthlete = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setAthleteDescription("");
        };
    };
    const ValueKeyPressAthleteBio = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setAthleteBio("");
        };
    };
    const ValueKeyPressCharity = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setCharityDescription("");
        };
    };
    const ValueKeyPressCharityBio = (e) => {
        const code = e.keycode || e.which;
        if(code === 8 && e.target.innerHTML === "<p><br></p>") {
            setCharityBio("");
        };
    };
    // function isValidURL(string, state) {
    //     if(state && string == ""){
    //         return true;
    //     }


    //     var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    //     return (res !== null)
    // };
    function isValidURL(string,state) {
        if(state && string == ""){
            return true;
        }
        var res = string.match(/^(https?:\/\/)?(www\.)?(facebook|instagram|twitter)\.com\/.+$/);
        return res !== null;
    }
    

    
    const validateEmail = (email, state) => {
        if(state && email == ""){
            return true;
        }
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    // Add Campaign Function
    const AddCampaignFct = async () => {
        if(ViewCampaignState?.title !== "" && ViewCampaignState?.subtitle !== "" && 
        ViewCampaignState?.description !== "" && 
        (AthleteImage !== "" && AthleteImage !== null) && 
        AthleteTitle !== "" && AthleteSubtitle !== "" && AthleteDescription !== "" && 
        // AthleteBio !== "" &&
        (CharityImage !== "" && CharityImage !== null) && 
        CharityTitle !== "" && CharitySubtitle !== "" && CharityDescription !== "" && 
        CommunityTitle !== "" && CommunityDescription !== "" &&
        isValidURL(CommunitySocial.youtube, true) && 
        isValidURL(CommunitySocial.facebook, true) && 
        isValidURL(CommunitySocial.twitter, true) && 
        isValidURL(CommunitySocial.instagram, true) &&
        ( DefaultAmount == true ?  !MinimumAmount ? false: true: true )&&
        (SponsorsLogo?.length>0) && 
        validateEmail(CommunitySocial.mail, true) && 
        SponsorsTitle!=="" && 
        DigitValue != ""
        // && CharityBio !== ""
        ) {
            const jsonData = {
                title: ViewCampaignState?.title.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                subtitle: ViewCampaignState?.subtitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                description: encodeURI(ViewCampaignState?.description),
                sequence_no: DigitValue,
                athlete: {
                    image: AthleteImage.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    title: AthleteTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    subtitle: AthleteSubtitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    youtubevideo: AthleteYoutubevideo,
                    description: AthleteDescription.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    bio: AthleteBio.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    button_title: AthleteButtonTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                },
                charity: {
                    Logo: Logo,
                    campaign_video: CampingVideo,
                    CommunityImage: CommunityImage,
                    CommunityTitle: CommunityTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    CommunityDescription: CommunityDescription.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    image: CharityImage,
                    title: CharityTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    subtitle: CharitySubtitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    youtubevideo: CharityYoutubevideo,
                    description: CharityDescription.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    bio: CharityBio.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    ContributeTitle:ContributeTitle?ContributeTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'):"",
                    MinimumAmount:MinimumAmount?MinimumAmount:"",
                    DefaultAmount: DefaultAmount ? "true":"false",
                    YouTubeLinks:YouTubeLinks?.length>0?YouTubeLinks:[],
                    CommunityLogo: CommunityLogo?.length>0?CommunityLogo:[],
                    CommunitySocial:CommunitySocial,
                    SponsorsLogo:SponsorsLogo,
                    SponsorsTitle:SponsorsTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    SocialLogo:SocialLogo,
                    button_title: CharityButtonTitle//.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    
                }
            };
            // console.log("jsonData", jsonData)
            const jsonDatas = JSON.stringify(jsonData);
            const response = await AddCampaign(accessToken, jsonDatas);
            if(response?.status === 200){
                setTimeout(() => {
                    dispatch(AddCampaignList(response?.data));
                    Navigate(CAMPAIGN_MANAGEMENT_URL);
                    toast.success(response.msg);
                }, 100);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else {
            CheckValid(SponsorsTitle, {type: 'SponsorsTitle', error: setErrorSponsorsTitle })
            CheckValid(ViewCampaignState?.title, {type: 'CampaignTitle', error: setErrorCampaignTitle });
            CheckValid(ViewCampaignState?.subtitle, {type: 'CampaignSubTitle', error: setErrorCampaignSubtitle });
            CheckValid(ViewCampaignState?.description, {type: 'CampaignDescription', error: setErrorCampaignDescription });
            CheckValid(AthleteImage, {type: 'avatar', error: setErrorAvatar });
            CheckValid(AthleteTitle, {type: 'CampaignAthleteTitle', error: setErrorAthleteTitle });
            CheckValid(AthleteSubtitle, {type: 'CampaignAthleteSubTitle', error: setErrorAthleteSubTitle });
            CheckValid(AthleteDescription, {type: 'CampaignAthleteDescription', error: setErrorAthleteDescription });
            CheckValid(AthleteBio, {type: 'CampaignAthleteBio', error: setErrorAthleteBio });
            CheckValid(CharityImage, {type: 'avatarCharity', error: setErrorAvatarCharity });
            CheckValid(SponsorsLogo, {type: 'sponsorLogo', error: setErrorSponsorsLogo });
            CheckValid(CharityTitle, {type: 'CampaignCharityTitle', error: setErrorCharityTitle });
            CheckValid(CharitySubtitle, {type: 'CampaignCharitySubTitle', error: setErrorCharitySubTitle });
            CheckValid(CharityDescription, {type: 'CampaignCharityDescription', error: setErrorCharityDescription });
            CheckValid(CharityBio, {type: 'CampaignCharityBio', error: setErrorCharityBio });
            CheckValid(CommunityImage, {type: 'CommunityImage', error: setErrorCommunityImage });
            CheckValid(CommunityDescription, {type: 'CommunityDescription', error: setErrorCommunityDescription });
            CheckValid(CommunityTitle, {type: 'CommunityTitle', error: setErrorCommunityTitle });
            CheckValid(DigitValue, {type: 'SequenceNumber', error: setErrorSequenceAmount });

            const Error = {}
            if(DefaultAmount == true && !MinimumAmount){
                setErrorDefaultAmount("Please Enter Amount");
            }
            if(!isValidURL(CommunitySocial.youtube, true)){
                Error.youtube = "Please Enter Valid Link";
            }
            if(!isValidURL(CommunitySocial.facebook, true)){
                Error.facebook = "Please Enter Valid Link";
            }
            if(!isValidURL(CommunitySocial.twitter, true)){
                Error.twitter = "Please Enter Valid Link";
            }
            if(!isValidURL(CommunitySocial.instagram, true)){
                Error.instagram = "Please Enter Valid Link";
            }
            if(!validateEmail(CommunitySocial.mail, true)){
                Error.mail = "Please Enter Valid Mail";
            }
            // if(!isValidURL(CommunitySocial.mail, true)){
            //     Error.mail = "Please Enter Valid Link";
            // }
            setCommunitySocial((data)=>({...data, ...Error}));
        };
    };

    console.log("ViewCampaignState?.title",ViewCampaignState)
    // Update Campaign Function
    const UpdateCampaignFct = async () => {
        if(ViewCampaignState?.title !== "" && ViewCampaignState?.subtitle !== "" && 
        ViewCampaignState?.description !== "" && 
        (AthleteImage !== "" && AthleteImage !== null) && 
        AthleteTitle !== "" && AthleteSubtitle !== "" && AthleteDescription !== "" && 
        // AthleteBio !== "" &&
        (CharityImage !== "" && CharityImage !== null) && 
        CharityTitle !== "" && CharitySubtitle !== "" && CharityDescription !== "" &&
        CommunityTitle !== "" && CommunityDescription !== "" &&
        isValidURL(CommunitySocial.youtube, true) && 
        isValidURL(CommunitySocial.facebook, true) && 
        isValidURL(CommunitySocial.twitter, true) && 
        ( DefaultAmount == true ?  !MinimumAmount ? false: true: true )&&
        isValidURL(CommunitySocial.instagram, true) &&
        validateEmail(CommunitySocial.mail, true) && 
        (SponsorsLogo?.length>0) && 
        SponsorsTitle!=="" && 
        DigitValue != ""
        // && CharityBio !== ""
        ) {
            // console.log("AthleteButtonTitle", AthleteButtonTitle)
            const jsonData = {
                slug: roomId,
                title: ViewCampaignState?.title.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                subtitle: ViewCampaignState?.subtitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                description: encodeURI(ViewCampaignState?.description),
                sequence_no: DigitValue ? DigitValue : "",
                is_active: ViewCampaignState?.is_active ? 1 :0,
                athlete: {
                    image: AthleteImage,
                    title: AthleteTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    subtitle: AthleteSubtitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    youtubevideo: AthleteYoutubevideo,
                    description: AthleteDescription.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    bio: AthleteBio.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    button_title: AthleteButtonTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                },
                charity: {
                    campaign_video: CampingVideo,
                    Logo: Logo,
                    CommunityImage: CommunityImage,
                    CommunityTitle: CommunityTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    CommunityDescription: CommunityDescription.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    image: CharityImage,
                    title: CharityTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    subtitle: CharitySubtitle.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    youtubevideo: CharityYoutubevideo,
                    description: CharityDescription.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    bio: CharityBio.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    ContributeTitle:ContributeTitle?ContributeTitle.replaceAll('"', '&#34').replaceAll("'", '&#39'):"",
                    MinimumAmount:MinimumAmount?MinimumAmount:"",
                    DefaultAmount: DefaultAmount ? "true":"false",
                    YouTubeLinks:YouTubeLinks?.length>0?YouTubeLinks:[],
                    CommunityLogo: CommunityLogo?.length>0?CommunityLogo:[],
                    CommunitySocial:CommunitySocial,
                    SponsorsLogo:SponsorsLogo,
                    SponsorsTitle:SponsorsTitle?.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    SocialLogo:SocialLogo,
                    button_title: CharityButtonTitle//.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                }
            };
            const jsonDatas = JSON.stringify(jsonData);
            const response = await EditCampaign(accessToken, jsonDatas);
            if(response?.status === 200){
                setTimeout(() => {
                    dispatch(UpdateCampaignX(response?.data));
                    // console.log("response?.data",response?.data)
                    Navigate(CAMPAIGN_MANAGEMENT_URL);
                    toast.success(response.msg);
                }, 100);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else {
            CheckValid(SponsorsTitle, {type: 'SponsorsTitle', error: setErrorSponsorsTitle })
            CheckValid(ViewCampaignState?.title, {type: 'CampaignTitle', error: setErrorCampaignTitle });
            CheckValid(ViewCampaignState?.subtitle, {type: 'CampaignSubTitle', error: setErrorCampaignSubtitle });
            CheckValid(ViewCampaignState?.description, {type: 'CampaignDescription', error: setErrorCampaignDescription });
            CheckValid(AthleteImage, {type: 'avatar', error: setErrorAvatar });
            CheckValid(AthleteTitle, {type: 'CampaignAthleteTitle', error: setErrorAthleteTitle });
            CheckValid(AthleteSubtitle, {type: 'CampaignAthleteSubTitle', error: setErrorAthleteSubTitle });
            CheckValid(AthleteDescription, {type: 'CampaignAthleteDescription', error: setErrorAthleteDescription });
            // CheckValid(AthleteBio, {type: 'CampaignAthleteBio', error: setErrorAthleteBio });
            CheckValid(CharityImage, {type: 'avatarCharity', error: setErrorAvatarCharity });
            CheckValid(SponsorsLogo, {type: 'sponsorLogo', error: setErrorSponsorsLogo });
            CheckValid(CharityTitle, {type: 'CampaignCharityTitle', error: setErrorCharityTitle });
            CheckValid(CharitySubtitle, {type: 'CampaignCharitySubTitle', error: setErrorCharitySubTitle });
            CheckValid(CharityDescription, {type: 'CampaignCharityDescription', error: setErrorCharityDescription });
            CheckValid(CommunityImage, {type: 'CommunityImage', error: setErrorCommunityImage });
            CheckValid(CommunityDescription, {type: 'CommunityDescription', error: setErrorCommunityDescription });
            CheckValid(CommunityTitle, {type: 'CommunityTitle', error: setErrorCommunityTitle });
            CheckValid(DigitValue, {type: 'SequenceNumber', error: setErrorSequenceAmount });

            // CheckValid(CharityBio, {type: 'CampaignCharityBio', error: setErrorCharityBio });
            const Error = {}
            if(DefaultAmount == true && !MinimumAmount){
                setErrorDefaultAmount("Please Enter Amount");
            }
            if(!isValidURL(CommunitySocial.youtube, true)){
                Error.youtube = "Please Enter Valid Link";
            }
            if(!isValidURL(CommunitySocial.facebook, true)){
                Error.facebook = "Please Enter Valid Link";
            }
            if(!isValidURL(CommunitySocial.twitter, true)){
                Error.twitter = "Please Enter Valid Link";
            }
            if(!isValidURL(CommunitySocial.instagram, true)){
                Error.instagram = "Please Enter Valid Link";
            }
            if(!validateEmail(CommunitySocial.mail, true)){
                Error.mail = "Please Enter Valid Mail";
            }
            setErrorCommunitySocial((data)=>({...data, ...Error}));
        };
    };
// console.log("SponsorsLogo", SponsorsLogo)
    // upload image 
    const UploadImage = async (e, url) => {
        const files = e.target.files[0];

        // Check for file size (5MB = 5 * 1024 * 1024 bytes)
        const maxFileSize = 5 * 1024 * 1024;  
        if (files.size > maxFileSize) { 
            toast.error("File size should not exceed 5MB");
            return;  
        } 
     
       const validFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

        if (!validFormats.includes(files.type)) {
            toast.error("Invalid file type. Please select an image file (jpg, jpeg, png, webp).");
            return; 
        }
        const formData = new FormData();
        formData.append("file", files, files.name);
    
    
        if(url === "") {
            // const files = e.target.files[0]; 
            // const formData = new FormData(); 
            // formData.append("file", files, files.name); 
            const fileresponse = await UploadFile(accessToken, formData);
            if(fileresponse) {
                setCommunityImage(fileresponse?.file_url_400);
            };
        }else if(url === "SocialLogo") {
            // const files = e.target.files[0]; 
            // const formData = new FormData();
            // formData.append("file", files, files.name);
            const fileresponse = await UploadFile(accessToken, formData);
            if(fileresponse) {
                const oldImg = [];
                SocialLogo?.map((item)=>{
                    oldImg.push(item);
                })
                oldImg.push(fileresponse?.file_url_400);
                setSocialLogo(oldImg);
            };
        }else if(url === "SponsorsLogo") {
            // const files = e.target.files[0];
            // const formData = new FormData();
            // formData.append("file", files, files.name);
            const fileresponse = await UploadFile(accessToken, formData);
            if(fileresponse) {
                const oldImg = [];
                SponsorsLogo?.map((item)=>{
                    oldImg.push(item);
                })
                oldImg.push(fileresponse?.file_url_400);
                setSponsorsLogo(oldImg);
            };
        }else if(url === "logos") {
            // const files = e.target.files[0];
            // const formData = new FormData();
            // formData.append("file", files, files.name);
            const fileresponse = await UploadFile(accessToken, formData);
            if(fileresponse) {
                const oldImg = [];
                CommunityLogo?.map((item)=>{
                    oldImg.push(item);
                })
                oldImg.push(fileresponse?.file_url_400);
                setCommunityLogo(oldImg);
            };
        }else if(url === "Logo") {
            setUploadFileLoader(true);
            // const files = e.target.files[0];
            // const formData = new FormData();
            // formData.append("file", files, files.name);
            const fileresponse = await UploadFile(accessToken, formData);
            if(fileresponse) {
                setUploadFileLoader(false);
                setLogo(fileresponse?.file_url_400);
            };
        } else if(url === "Athlete") {
            setUploadFileLoader(true);
            // const files = e.target.files[0];
            // const formData = new FormData();
            // formData.append("file", files, files.name);
            const fileresponse = await UploadFile(accessToken, formData);
            if(fileresponse) {
                setUploadFileLoader(false);
                setAthleteImage(fileresponse?.file_url_400);
                setErrorAvatar("");
            };
        } else {
            setUploadCharityFileLoader(true);
            // const files = e.target.files[0];
            // const formData = new FormData();
            // formData.append("file", files, files.name);
            const fileresponse = await UploadFile(accessToken, formData);
            if(fileresponse) {
                setUploadCharityFileLoader(false);
                setCharityImage(fileresponse?.file_url_400);
                setErrorAvatarCharity("");
            };
        }
    };
    

    // upload media files
    const UploadFiles = (url) => {
        setShowFileModal({
            ...showFileModal,
            open: !showFileModal?.open,
            title: "Add Video",
            method: url
        });
    };

    const callBackSelect = (item) =>{
        const data = YouTubeLinks;
        data.push(item);
        setYouTubeLinks(data);
    }
    function getYouTubeVideoId(url) {
        // Match the YouTube video ID pattern in the URL
        const match = url?.match(/(?:youtu\.be\/|youtube\.com\/(?:.*v\/|.*[?&]v=))([^"&?\/\s]{11})/);
    
        // If there is a match, return the video ID, else return null
        return match ? match[1] : null;
    }
    const YoutubePreview = ({item, index}) =>{

        const onClose = () =>{
            const getData = YouTubeLinks?.filter((item, index1)=>index1 !== index);
            setYouTubeLinks(getData)
        }
        const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line

        const VimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?)/;
        if(VimeoRegex.test(item) === true) {
            const vimeoLink = getVimeoVideoId(item)
            return(
                <div  className="youtube_link-wrapper me-2 mb-2 p-0">
                    <iframe
                        className="youtube_link"
                        src={`https://player.vimeo.com/video/${vimeoLink}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                    <button onClick={onClose} className="close-button btn p-0 border-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                        </svg>
                    </button>
                </div>
            )
        }else{
            const link = `https://www.youtube.com/embed/${getYouTubeVideoId(item)}`;
            return(
                <div  className="youtube_link-wrapper me-2 mb-2 p-0">
                    <iframe
                        className="youtube_link"
                        src={link}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                    <button onClick={onClose} className="close-button btn p-0 border-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                        </svg>
                    </button>
                </div>
            )
        }
    }
    const callRemoveLogo = (e) =>{
        const items = CommunityLogo?.filter((item, index)=>index !== e);
        // console.log("items", items)
        setCommunityLogo(items);
    }
    const callRemoveSponsorsLogo = (e) =>{
        const items = SponsorsLogo?.filter((item, index)=>index !== e);
        setSponsorsLogo(items);
    }
    const callRemoveSocialLogo = (e) =>{
        const items = SocialLogo?.filter((item, index)=>index !== e);
        setSocialLogo(items);
    }
    // const onChangeSocial = (e)=>{
        
    //     setCommunitySocial((data)=>({
    //         ...data,
    //         [e.target.name]:e.target.value
    //     }));
    //     if(!isValidURL(e.target.value) && e.target.value && e.target.name != "mail"){
    //         setErrorCommunitySocial((data)=>({
    //             ...data,
    //             [e.target.name]:"Please Enter Valid Link"
    //         }))
    //     }else if(!validateEmail(e.target.value) && e.target.value && e.target.name == "mail"){
    //         setErrorCommunitySocial((data)=>({
    //             ...data,
    //             [e.target.name]:"Please Enter Valid Mail"
    //         }))
    //     }else{
    //         setErrorCommunitySocial((data)=>({
    //             ...data,
    //             [e.target.name]:""
    //         }))
    //     }
    // }
    const onChangeSocial = (e) => {
        const { name, value } = e.target;
        setCommunitySocial((data) => ({
            ...data,
            [name]: value
        }));
    
        if (name !== "mail") {
            if (!isValidURL(value)) {
                setErrorCommunitySocial((data) => ({
                    ...data,
                    [name]: "Please enter a valid " + name.charAt(0).toUpperCase() + name.slice(1) + " URL"
                }));
            } else {
                setErrorCommunitySocial((data) => ({
                    ...data,
                    [name]: ""
                }));
            }
        } else {
            if (!validateEmail(value)) {
                setErrorCommunitySocial((data) => ({
                    ...data,
                    [name]: "Please enter a valid email address"
                }));
            } else {
                setErrorCommunitySocial((data) => ({
                    ...data,
                    [name]: ""
                }));
            }
        }
    };
    const onChange = (event) =>{
        const inputValue = event.target.value;
        // const digitOnly = inputValue.replace(/\D/g, '');

        if (inputValue !== '') {
            //  inputValue = parseInt(inputValue);
            if (inputValue > 0) {
                setDigitValue(inputValue);
            } else {
                setDigitValue('');
            }
        } else {
        setDigitValue('');
        }
    }
    // console.log("DigitValue",DigitValue)

    const onChange2 = (e) => {
        const inputValue = e.target.value;
        if (e.target.type === "text") {
            // const isNumeric = /^\d+$/.test(inputValue);
            const isNumeric = /^\d*\.?\d{0,2}$/.test(inputValue);

            if (!isNumeric && inputValue) {
                e.target.value = MinimumAmount;
            }
            if (parseFloat(inputValue) <= 0) {
                e.target.value = "";
            }
            if (parseFloat(inputValue) > 999999) {
                e.target.value = MinimumAmount;
                console.log("Value should not be greater than 10 lakhs");
            }
        }

        // setAmount(e.target.value);
        setMinimumAmount(e.target.value);
        setErrorDefaultAmount("");
    };

    // console.log("MinimumAmount",MinimumAmount)
    function getYouTubeVideoId(url) {
        // Match the YouTube video ID pattern in the URL
        const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:.*v\/|.*[?&]v=))([^"&?\/\s]{11})/);
    
        // If there is a match, return the video ID, else return null
        return match ? match[1] : null;
    }
    const getVimeoVideoId = (url) => {
        const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?)/;
        const match = url.match(vimeoRegex);
        if (match && match[2]) {
          return match[2];
        }
        return null;
      };
    const callPreviewLink = (CharityYoutubevideo) =>{
        const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line

        const VimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?)/;
        if(YouTubeRegex.test(CharityYoutubevideo) === true) {
            return <iframe src={`https://www.youtube.com/embed/${CharityYoutubevideo?.split("/")[CharityYoutubevideo?.split("/").length - 1].replace("watch?v=", "")}`}></iframe>
        }
        const vimeoLink = getVimeoVideoId(CharityYoutubevideo)
        if(VimeoRegex.test(CharityYoutubevideo) === true && vimeoLink) {
            return <iframe src={`https://player.vimeo.com/video/${vimeoLink}`}></iframe>
        }
    }
    // console.log("CurrenUrlBase",CurrenUrlBase)
    return(<>
    <ul className="tabcomnsection">
        {CurrenUrlBase === "add-campaign" ? (
            <li className={CurrenUrlBase === "add-campaign" ? "active" : ""} onClick={() => dispatch(SelectCampaignTab("add-campaign"))}>Add</li>
        ) : (<>
            {/* <li className={CurrenUrlBase === "view-campaign" ? "active" : ""} onClick={() => dispatch(SelectCampaignTab("view-campaign"))}>View</li> */}
            <li className={CurrenUrlBase === "edit-campaign" ? "active" : ""} onClick={() => dispatch(SelectCampaignTab("edit-campaign"))}>Edit</li>
        </>)}
        <div className="tabheadebuttns">
        
            {CurrenUrlBase === "edit-campaign" && (<Button className="savchanges" onClick={() => UpdateCampaignFct()}>Save</Button>)}
            {CurrenUrlBase === "add-campaign" && (<Button className="savchanges" onClick={() => AddCampaignFct()}>Add</Button>)}
            <Link className="btn savchanges linksmanage" to={CAMPAIGN_MANAGEMENT_URL} >Back</Link>
        </div>
    </ul>
    <section className="formsectionset">
        {(CurrenUrlBase === "add-campaign" || CurrenUrlBase === "edit-campaign") && (<div className="tabcontent">
            <Scrollbars 
                style={{ height: "calc(100vh - 162px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="formloginset formeditset">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Logo</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Label>Provided by Logo</Form.Label>
                                {(Logo !== undefined && Logo !== "") ? (<div className="imageuploads">
                                    <Image src={Logo} alt="charity avatar" />
                                    <span className="closeIcon" onClick={() => setLogo("")}></span>
                                </div>) :
                                (<div className="uploadbuttons"
                                   onClick={() => document.getElementById('fileInput1')?.click()}

                                >
                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                        {UploadFileLoader ? 
                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                        (<>
                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                            <input type="file" id="fileInput1" className="uploadfilInput" onChange={(e) => UploadImage(e, "Logo")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                        </>)}
                                    </div>
                                    <div className="uploadfilebox">
                                        <Image src={DefaultImage} alt="upload image" />
                                    </div>
                                </div>)}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={ViewCampaignState?.title} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CampaignTitle', error: setErrorCampaignTitle })} 
                                    onChange={(e) => setViewCampaignState({...ViewCampaignState, title: e.target.value})} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter title" 
                                    maxLength={100}
                                />
                                {errorCampaignTitle && <div className="danger-colorset">{errorCampaignTitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Subtitle</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={ViewCampaignState?.subtitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CampaignSubTitle', error: setErrorCampaignSubtitle })} 
                                    onChange={(e) => setViewCampaignState({...ViewCampaignState, subtitle: e.target.value})} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter Subtitle"
                                    maxLength={60}
                                    
                                />
                                {errorCampaignSubtitle && <div className="danger-colorset">{errorCampaignSubtitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Description</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="editorBox">
                                <ReactQuill 
                                    value={ViewCampaignState?.description}
                                    onChange={(e) => setViewCampaignState({...ViewCampaignState, description: e})}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignDescription', error: setErrorCampaignDescription })} 
                                    onKeyDown={(e) =>setTimeout(()=> ValueKeyPress(e), 10)}
                                />
                                {errorCampaignDescription && <div className="danger-colorset">{errorCampaignDescription}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Video</h4>
                                <p>Add Video link</p>
                                <Button className="uploadcoverMediabtn" onClick={() => setCampingVideo("")}>
                                    Remove Video
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="coverUploadImage" onClick={() => UploadFiles("campagin_youtube_link")}>
                                {CampingVideo !== "" ?(<div className="uploadeyoutubevideo">
                                    {callPreviewLink(CampingVideo)}
                                    {/* <iframe src={`https://www.youtube.com/embed/${getYouTubeVideoId(CampingVideo)}`}></iframe> */}
                                </div>) :
                                (<div className="coverUploadImageSet">
                                    <Image src={UploadImageIcon} className="uplodimgicon" alt="uploadImage" />  
                                    <h4>Add link here</h4>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Sequence Number</h4>    
                        </div>
                    </div>
                    <div className="col-md-8">
                    {/* <Form className="switchbtn" onSubmit={callUpdateIndex}>
                    <Form.Control 
                        style={{width: 60, boxShadow:"none", fontSize:12}}
                        onChange={onChange}
                        value={DigitValue}
                        size="sm"
                        type="text"
                    />
                    {(DigitValue != user?.sequence_no && DigitValue!==null && DigitValue!=="") && (
                        <button disabled={Loader} className={"btn btn-primary btn-sm ms-2 cmnfilterHeadbtn"} type="submit">
                            Save
                        </button>
                    )}
                </Form> */}
                <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    // value={ViewCampaignState?.subtitle} 
                                    // onChange={(e) => setViewCampaignState({...ViewCampaignState, subtitle: e.target.value})} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'SequenceNumber', error: setErrorSequenceAmount })} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter Sequence Number"
                                    onChange={onChange}
                                    value={DigitValue}
                                      
                                />
                                {errorsequenceAmount && <div className="danger-colorset">{errorsequenceAmount}</div>}
                            </Form.Group>


                    </div>
                    </div>
                    <div className="row">
                        <div className="formgroups divider-Header">
                            <h3>Athlete :</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Avatar</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Label>Athlete Avatar</Form.Label>
                                {(AthleteImage !== undefined && AthleteImage !== "") ? (<div className="imageuploads">
                                    <Image src={AthleteImage} alt="charity avatar" />
                                    <span className="closeIcon" onClick={() => setAthleteImage("")}></span>
                                </div>) :
                                (<div className="uploadbuttons"
                                 onClick={() => document.getElementById('fileInput2')?.click()}
                                >
                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                        {UploadFileLoader ? 
                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                        (<>
                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                            <input type="file" id="fileInput2" className="uploadfilInput" onChange={(e) => UploadImage(e, "Athlete")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                        </>)}
                                    </div>
                                    <div className="uploadfilebox">
                                        <Image src={DefaultImage} alt="upload image" />
                                    </div>
                                </div>)}
                                {errorAvatar && <div className="danger-colorset">{errorAvatar}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={AthleteTitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CampaignAthleteTitle', error: setErrorAthleteTitle })} 
                                    onChange={(e) => setAthleteTitle(e.target.value)} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter title" 
                                    maxLength={100}
                                />
                                {errorAthleteTitle && <div className="danger-colorset">{errorAthleteTitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Subtitle</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={AthleteSubtitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CampaignAthleteSubTitle', error: setErrorAthleteSubTitle })} 
                                    onChange={(e) => setAthleteSubtitle(e.target.value)} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter Subtitle" 
                                    maxLength={60}
                                />
                                {errorAthleteSubTitle && <div className="danger-colorset">{errorAthleteSubTitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Video</h4>
                                <p>Add Video link</p>
                                <Button className="uploadcoverMediabtn" onClick={() => setAthleteYoutubevideo("")}>
                                    Remove Video
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="coverUploadImage" onClick={() => UploadFiles("athlete")}>
                                {AthleteYoutubevideo !== "" ?(<div className="uploadeyoutubevideo">
                                    {callPreviewLink(AthleteYoutubevideo)}
                                    {/* <iframe src={`https://www.youtube.com/embed/${AthleteYoutubevideo?.split("/")[AthleteYoutubevideo?.split("/").length - 1].replace("watch?v=", "")}`}></iframe> */}
                                </div>) :
                                (<div className="coverUploadImageSet">
                                    <Image src={UploadImageIcon} className="uplodimgicon" alt="uploadImage" />  
                                    <h4>Add link here</h4>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="">Button Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={AthleteButtonTitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'AthleteButtonTitle', error: setErrorCampaignTitle })} 
                                    onChange={(e) => setAthleteButtonTitle(e.target.value)} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter Button Title" 
                                    maxLength={20}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Description</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="editorBox">
                                {/* <textarea
                                    value={AthleteDescription} 
                                    onChange={(e) => setAthleteDescription(e.target.value)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignAthleteDescription', error: setErrorAthleteDescription })} 
                                ></textarea> */}
                                <ReactQuill 
                                    value={AthleteDescription} 
                                    onChange={(e) => setAthleteDescription(e)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignAthleteDescription', error: setErrorAthleteDescription })} 
                                    onKeyDown={(e) =>setTimeout(()=> ValueKeyPressAthlete(e), 10)}
                                />
                                {errorAthleteDescription && <div className="danger-colorset">{errorAthleteDescription}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Bio</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="editorBox">
                                {/* <textarea
                                    value={AthleteBio} 
                                    onChange={(e) => setAthleteBio(e.target.value)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignAthleteBio', error: setErrorAthleteBio })} 
                                ></textarea> */}
                                <ReactQuill 
                                    value={AthleteBio} 
                                    onChange={(e) => setAthleteBio(e)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignAthleteBio', error: setErrorAthleteBio })} 
                                    onKeyDown={(e) =>setTimeout(()=> ValueKeyPressAthleteBio(e), 10)}
                                />
                                {/* {errorAthleteBio && <div className="danger-colorset">{errorAthleteBio}</div>} */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="">Button Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={AthleteButtonTitle} 
                                    onChange={(e) => setAthleteButtonTitle(e.target.value)} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter Button Title" 
                                    maxLength={20}
                                />
                            </Form.Group>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="formgroups divider-Header">
                            <h3>Charity :</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Avatar</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Label>Charity Avatar</Form.Label>
                                {(CharityImage !== undefined && CharityImage !== "") ? (<div className="imageuploads">
                                    <Image src={CharityImage} alt="charity avatar" />
                                    <span className="closeIcon" onClick={() => setCharityImage("")}></span>
                                </div>) :
                                (<div className="uploadbuttons"
                                 onClick={() => document.getElementById('fileInput3')?.click()}

                                >
                                    <div className={UploadCharityFileLoader ? "fileloader active" : "fileloader"}>
                                        {UploadCharityFileLoader ? 
                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                        (<>
                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                            <input type="file" id="fileInput3" className="uploadfilInput" onChange={(e) => UploadImage(e, "Charity")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                        </>)}
                                    </div>
                                    <div className="uploadfilebox">
                                        <Image src={DefaultImage} alt="upload image" />
                                    </div>
                                </div>)}
                                {errorAvatarCharity && <div className="danger-colorset">{errorAvatarCharity}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={CharityTitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CampaignCharityTitle', error: setErrorCharityTitle })} 
                                    onChange={(e) => setCharityTitle(e.target.value)} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter title" 
                                    maxLength={100}
                                />
                                {errorCharityTitle && <div className="danger-colorset">{errorCharityTitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Subtitle</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={CharitySubtitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CampaignCharitySubTitle', error: setErrorCharitySubTitle })} 
                                    onChange={(e) => setCharitySubtitle(e.target.value)} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter Subtitle" 
                                    maxLength={60}

                                />
                                {errorCharitySubTitle && <div className="danger-colorset">{errorCharitySubTitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Video</h4>
                                <p>Add Video link</p>
                                <Button className="uploadcoverMediabtn" onClick={() => setCharityYoutubevideo("")}>
                                    Remove Video
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="coverUploadImage" onClick={() => UploadFiles("charity")}>
                                {CharityYoutubevideo !== "" ?(<div className="uploadeyoutubevideo">
                                    {callPreviewLink(CharityYoutubevideo)}
                                </div>) :
                                (<div className="coverUploadImageSet">
                                    <Image src={UploadImageIcon} className="uplodimgicon" alt="uploadImage" />  
                                    <h4>Add link here</h4>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Description</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="editorBox">
                                {/* <textarea
                                    value={CharityDescription} 
                                    onChange={(e) => setCharityDescription(e.target.value)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignCharityDescription', error: setErrorCharityDescription })} 
                                ></textarea> */}
                                <ReactQuill 
                                    value={CharityDescription} 
                                    onChange={(e) => setCharityDescription(e)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignCharityDescription', error: setErrorCharityDescription })} 
                                    onKeyDown={(e) =>setTimeout(()=> ValueKeyPressCharity(e), 10)}
                                />
                                {errorCharityDescription && <div className="danger-colorset">{errorCharityDescription}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Bio</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="editorBox">
                                {/* <textarea
                                    value={CharityBio} 
                                    onChange={(e) => setCharityBio(e.target.value)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignCharityBio', error: setErrorCharityBio })} 
                                ></textarea> */}
                                <ReactQuill 
                                    value={CharityBio} 
                                    onChange={(e) => setCharityBio(e)}
                                    onKeyUp={(e) => CheckValid(e, {type: 'CampaignCharityBio', error: setErrorCharityBio })} 
                                    onKeyDown={(e) =>setTimeout(()=> ValueKeyPressCharityBio(e), 10)}
                                />
                                {/* {errorCharityBio && <div className="danger-colorset">{errorCharityBio}</div>} */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="">Button Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={CharityButtonTitle} 
                                    onChange={(e) => setCharityButtonTitle(e.target.value)} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    placeholder="Enter Button Title" 
                                    maxLength={20}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="formgroups divider-Header">
                            <h3>Community Support :</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Image</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Label>Community Image</Form.Label>
                                {(CommunityImage !== undefined && CommunityImage !== "") ? (<div className="imageuploads">
                                    <Image src={CommunityImage} alt="charity avatar" />
                                    <span className="closeIcon" onClick={() => setCommunityImage("")}></span>
                                </div>) :
                                (<div className="uploadbuttons"
                               onClick={() => document.getElementById('fileInput4')?.click()}

                                >
                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                        {UploadFileLoader ? 
                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                        (<>
                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                            <input type="file" id="fileInput4" className="uploadfilInput" onChange={(e) => UploadImage(e, "")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                        </>)}
                                    </div>
                                    <div className="uploadfilebox">
                                        <Image src={DefaultImage} alt="upload image" />
                                    </div>
                                </div>)}
                                {errorCommunityImage && <div className="danger-colorset">{errorCommunityImage}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={CommunityTitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CommunityTitle', error: setErrorCommunityTitle })} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                    onChange={(e) => setCommunityTitle(e.target.value)} 
                                    placeholder="Enter title"
                                    maxLength={100}

                                />
                                {errorCommunityTitle && <div className="danger-colorset">{errorCommunityTitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Description</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    as={"textarea"}
                                    value={CommunityDescription} 
                                    onChange={(e) => setCommunityDescription(e.target.value)}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'CommunityDescription', error: setErrorCommunityDescription })} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                    placeholder="Enter description"
                                    maxLength={1000}
                                />
                                {errorCommunityDescription && <div className="danger-colorset">{errorCommunityDescription}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Supporting Partners Logo</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <div className="uploadbuttons me-2 mb-2"
                                onClick={() => document.getElementById('fileInput5')?.click()}

                                >
                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                        {UploadFileLoader ? 
                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                        (<>
                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                            <input type="file" id="fileInput5" className="uploadfilInput" onChange={(e) => UploadImage(e, "logos")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                        </>)}
                                    </div>
                                    <div className="uploadfilebox">
                                        <Image src={DefaultImage} alt="upload image" />
                                    </div>
                                </div>
                                {CommunityLogo?.map((item, index)=>(
                                    <React.Fragment key={index}>
                                        <div className="imageuploads bg-grey me-2 mb-2">
                                            <Image src={item} alt="charity avatar" />
                                            <span className="closeIcon" onClick={() => callRemoveLogo(index)}></span>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="formgroups divider-Header">
                            <h3>Sponsors :</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Sponsors Logo</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <div className="uploadbuttons me-2 mb-2"
                                   onClick={() => document.getElementById('fileInput6')?.click()}

                                >
                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                        {UploadFileLoader ? 
                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                        (<>
                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                            <input type="file" id="fileInput6" className="uploadfilInput" onChange={(e) => UploadImage(e, "SponsorsLogo")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                        </>)}
                                    </div>
                                    <div className="uploadfilebox">
                                        <Image src={DefaultImage} alt="upload image" />
                                    </div>
                                </div>
                                {SponsorsLogo?.map((item, index)=>(
                                    <React.Fragment key={index}>
                                        <div className="imageuploads bg-grey me-2 mb-2">
                                            <Image src={item} alt="charity avatar" />
                                            <span className="closeIcon" onClick={() => callRemoveSponsorsLogo(index)}></span>
                                        </div>
                                    </React.Fragment>
                                ))}
                                {errorSponsorsLogo && <div className="danger-colorset">{errorSponsorsLogo}</div>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4 className="label-required">Title</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="text" 
                                    value={SponsorsTitle} 
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'SponsorsTitle', error: setErrorSponsorsTitle })} 
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                    onChange={(e) => setSponsorsTitle(e.target.value)} 
                                    placeholder="Enter title"
                                    maxLength={100}

                                />
                                {errorSponsorsTitle && <div className="danger-colorset">{errorSponsorsTitle}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="formgroups divider-Header">
                            <h3>Social links :</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Logo</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <div className="uploadbuttons me-2 mb-2"
                                 onClick={() => document.getElementById('fileInput7')?.click()}

                                >
                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                        {UploadFileLoader ? 
                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                        (<>
                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                            <input type="file" id="fileInput7" className="uploadfilInput" onChange={(e) => UploadImage(e, "SocialLogo")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                        </>)}
                                    </div>
                                    <div className="uploadfilebox">
                                        <Image src={DefaultImage} alt="upload image" />
                                    </div>
                                </div>
                                {SocialLogo?.map((item, index)=>(
                                    <React.Fragment key={index}>
                                        <div className="imageuploads bg-grey me-2 mb-2">
                                            <Image src={item} alt="charity avatar" />
                                            <span className="closeIcon" onClick={() => callRemoveSocialLogo(index)}></span>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Facebook</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control
                                    type="text" 
                                    value={CommunitySocial.facebook} 
                                    onChange={onChangeSocial}
                                    name="facebook"
                                    placeholder="Enter Link"
                                />
                                {errorCommunitySocial.facebook && <div className="danger-colorset">{errorCommunitySocial.facebook}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Instagram</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control
                                    type="text" 
                                    value={CommunitySocial.instagram} 
                                    onChange={onChangeSocial}
                                    name="instagram"
                                    placeholder="Enter Link"
                                />
                                {errorCommunitySocial.instagram && <div className="danger-colorset">{errorCommunitySocial.instagram}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Twitter X</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control
                                    type="text" 
                                    value={CommunitySocial.twitter} 
                                    onChange={onChangeSocial}
                                    name="twitter"
                                    placeholder="Enter Link"
                                />
                                {errorCommunitySocial.twitter && <div className="danger-colorset">{errorCommunitySocial.twitter}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Youtube</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control
                                    type="text" 
                                    value={CommunitySocial.youtube} 
                                    onChange={onChangeSocial}
                                    name="youtube"
                                    placeholder="Enter Link"
                                />
                                {errorCommunitySocial.youtube && <div className="danger-colorset">{errorCommunitySocial.youtube}</div>}
                            </Form.Group>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formgroups">
                                <h4>Mail</h4>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form.Group className="mb-3" >
                                <Form.Control
                                    type="text" 
                                    value={CommunitySocial.mail} 
                                    onChange={onChangeSocial}
                                    name="mail"
                                    placeholder="Enter mail"
                                />
                                {errorCommunitySocial.mail && <div className="danger-colorset">{errorCommunitySocial.mail}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="formgroups divider-Header">
                            <h3>Youtube links :</h3>
                        </div>
                    </div>
                    <div className="row">
                        {YouTubeLinks?.map((item, index)=>(
                            <React.Fragment key={index}>
                                {YoutubePreview({item:item, index:index})}
                            </React.Fragment>
                        ))}
                        <div onClick={() => UploadFiles("youtube")} className="youtube_link me-2 mb-2">
                            <span>+</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="formgroups divider-Header">
                            <h3>Other :</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Contribute Today Title</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ContributeTitle} 
                                    onChange={(e) => setContributeTitle(e.target.value)} 
                                    placeholder="Enter title"
                                    maxLength={60}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Amount</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={MinimumAmount}
                                    // onKeyUp={(e) => CheckValid(e.target.value, {type: 'MinimumAmount', error: setErrorCharityTitle })} 
                                    // onChange={(e) =>{ setMinimumAmount(e.target.value); setErrorDefaultAmount("")}} 
                                    // onKeyDown={(e) => EmptySpaceFieldValid(e)}  
                                    // onChange={(e) => {
                                    //       const value = e.target.value;
                                    //         // Limit to 6 digits
                                    //      if (value.length <= 6) {
                                    //             setMinimumAmount(value);
                                    //             setErrorDefaultAmount("");
                                    //      }
                                    //      }} 
                                     onChange={onChange2}    
                                    // onKeyDown={(e) => {
                                    //   if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                                    //    e.preventDefault();
                                    //    }
                                    //  }}   
                                    onKeyDown={(e) => {
                                     const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
                                     if (!((e.key >= "0" && e.key <= "9") || e.key === "." || validKeys.includes(e.key))) {
                                     e.preventDefault();
                                     }
                                    }}
                                    placeholder="Enter Amount"
                                    // maxLength={6}
                                    // min={1}
                                    // pattern="[0-9]*" 
                                />
                                {errorDefaultAmount && <div className="danger-colorset">{errorDefaultAmount}</div>}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Check 
                                    checked={DefaultAmount} 
                                    className="editable"
                                    id="editable"
                                    onChange={(e) =>{ setDefaultAmount(e.target.checked); setErrorDefaultAmount("")}} 
                                    label="Set Default Amount"
                                    placeholder="Amount Placeholder"
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>)}

        {/* {CurrenUrlBase === "view-campaign" && (<div className="tabcontent">
            <Scrollbars 
                style={{ height: "calc(100vh - 162px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Campaign Name</Form.Label>
                            <div className="charityName">{CurrentCampaign?.title}</div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Campaign subtitle</Form.Label>
                            <div className="charityName">{CurrentCampaign?.subtitle}</div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group className="mb-3" >
                            <Form.Label>Description</Form.Label>
                            <div className="charityName" dangerouslySetInnerHTML={{__html: decodeURI(CurrentCampaign?.description)}}></div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="viewCompaignTitlte">Athlete :</h4>
                        {console.log("CurrentCampaign", JSON.parse(CurrentCampaign.athlete.replace("None", 'null')?.replace(/'/g, '"')))}
                    </div>
                    <div className="row" style={{ padding: "0px" }}>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label style={{ width: "100%" }}>Image</Form.Label>
                                <div className="imageuploads">
                                    <img src={JSON.parse(CurrentCampaign.athlete.replace("None", 'null')?.replace(/'/g, '"'))?.image} alt="Charity" />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Title</Form.Label>
                                <div className="charityName">
                                    {JSON.parse(CurrentCampaign.athlete.replace("None", 'null')?.replace(/'/g, '"'))?.title}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Subtitle</Form.Label>
                                <div className="charityName">
                                    {JSON.parse(CurrentCampaign.athlete.replace("None", 'null')?.replace(/'/g, '"'))?.subtitle}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "0px" }}>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Description</Form.Label>
                                <div className="charityName" dangerouslySetInnerHTML={{__html: decodeURI(JSON.parse(CurrentCampaign.athlete.replace("None", 'null')?.replace(/'/g, '"'))?.description)}}></div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Bio</Form.Label>
                                <div className="charityName" dangerouslySetInnerHTML={{__html: decodeURI(JSON.parse(CurrentCampaign.athlete.replace("None", 'null')?.replace(/'/g, '"'))?.bio)}}></div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="viewCompaignTitlte">Charity :</h4>
                    </div>
                    <div className="row" style={{ padding: "0px" }}>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label style={{ width: "100%" }}>Image</Form.Label>
                                <div className="imageuploads">
                                    <img src={JSON.parse(CurrentCampaign.charity.replace("None", 'null')?.replace(/'/g, '"'))?.image} alt="Charity" />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Title</Form.Label>
                                <div className="charityName">
                                    {JSON.parse(CurrentCampaign.charity.replace("None", 'null')?.replace(/'/g, '"'))?.title}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Subtitle</Form.Label>
                                <div className="charityName">
                                    {JSON.parse(CurrentCampaign.charity.replace("None", 'null')?.replace(/'/g, '"'))?.subtitle}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "0px" }}>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Description</Form.Label>
                                <div className="charityName" dangerouslySetInnerHTML={{__html: decodeURI(JSON.parse(CurrentCampaign.charity.replace("None", 'null')?.replace(/'/g, '"'))?.description)}}></div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Bio</Form.Label>
                                <div className="charityName" dangerouslySetInnerHTML={{__html: decodeURI(JSON.parse(CurrentCampaign.charity.replace("None", 'null')?.replace(/'/g, '"'))?.bio)}}></div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>)} */}

        {showFileModal?.open && (<UploadFileModal 
            type={"onlyVideo"}
            setAthleteYoutubevideo={setAthleteYoutubevideo}
            setCharityYoutubevideo={setCharityYoutubevideo}
            setCampingVideo={setCampingVideo}
            callBackSelect={callBackSelect}
            showFileModal={showFileModal} 
            setShowFileModal={setShowFileModal} 
        />)}
        {/* common loader */}
        {LoaderStart && <CommonLoader classNameS={"charityLoader"} />}
    </section>
</>)
}

export default ViewCampaign;