/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ADD_CAMPAIGN_MAIL, CREATE_MOSAIC_SOURCE_IMAGE, MAIL_CAMPAIGN_MANAGEMENT, MOSAIC_MANAGEMENT, UPDATE_CAMPAIGN_MAIL, VALIDATE_MOSAIC_SOURCE_IMAGE } from '../Shared/constant'
import { Button, Form, Modal, Image, Table, Badge, FormGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import UploadFile from "../../Api/UploadFile";
import LoaderImage from "../../assets/img/spinner_transferent.svg";
import DefaultImage from "../../assets/img/DefaultProduct.jpg";
import UploadImageIcon from "../../assets/img/uploadImage.png";
import GetCampaign from '../../Api/GetCampaign'
import { setStoreCampaignListOption } from '../../redux/actions/campaignAction'
import { PostRequestAPI } from '../../Api/PostRequest'
import { toast } from 'react-toastify'
import CommonLoader from '../Common/CommonLoader'
import MosaicLoader from '../Common/MosaicLoader'
import GetParentCampaign from '../../Api/GetParentCompaign'

export default function AddMosaic() {
    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{ 'align': [] }],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean'],
        ],
    };
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    const location = useLocation();
    const param = useParams();
    const [ShowModal, setShowModal] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { CurrenUrlBase, campaignOptionList } = useSelector((state) => state.campaignReducers);
    const { is_mosaic,parent_compaign_Id } = useSelector((state) => state.personalReducers);
    const [ loader, setLoader ] = useState(false);
    const [ loader2, setLoader2 ] = useState(false);
    const [load,setLoad] = useState(false);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [parentCompaignObject,setParentCompaignObject] = useState({})
    const [loding,setLoading] = useState(true)
    const [formData, setFormData] = useState({
        tile_size:"",
        file:null,
        campaign_id:"",
        opacity:null,
        utilize_blending:false,
        utilize_less_occurrence:false,
        name:""
    });
    const [error, setError] = useState({
        tile_size:"",
        file:[],
        opacity:"",
        name:"",
        campaign_id: ""


    });
    // const [opicity,setOpicty] = useState("")
    // useEffect(()=>{
    //     if(param?.campaign_mail_id && location?.state){
    //         setFormData((data)=>({
    //             ...data,
    //             tile_size: location?.state?.tile_size,
    //             file: location?.state?.file,
    //         }))
    //     }
    // },[param?.campaign_mail_id])

    useEffect(()=>{
        fetchData(1, 5000, "all");
        if(is_mosaic ==="yes"){
            fetchParentCompaignData()
        }
    },[])

    // Get data from api
    const fetchData = async (currentPage,userLimit,userType) => {
        const responce = await GetCampaign(accessToken, "", "active", userLimit, currentPage );
        if(responce?.status === 200) {
            setLoading(false)
            dispatch(setStoreCampaignListOption(responce.data));
        } else {
            dispatch(setStoreCampaignListOption([]));
        };
    };
    const fetchParentCompaignData = async() =>{
        const responce = await GetParentCampaign(accessToken,parent_compaign_Id);
        if(responce?.status===200){
        //    console.log("responce",responce?.data)
           setParentCompaignObject(responce?.data)
         }else{
            setParentCompaignObject()
         }

    }
    // const getMailCampaignDetails = async (id) =>{
    //     const response = await GetRequestCallAPI(`${GET_CAMPAIGN_MAIL}/${id}`, accessToken);
    //     console.log("response", response)
    // }
    const onChange = (e) => {
        let cleanedValue = e.target.value;

        if(e.target.type != "switch" ){
            cleanedValue = e.target.value.replace(/\s+/g, ' ');
        
            // If the name is 'tile_size', only allow positive digits
            if (e.target.name === 'tile_size') {
                cleanedValue = cleanedValue.replace(/\D/g, ''); // Remove non-digit characters
                if (cleanedValue === '') {
                    cleanedValue = ''; // Default to 0 if no positive digit is provided
                }
                if(cleanedValue>100){
                    cleanedValue = formData[e.target.name];
                }
            }
            
        }
    
        e.target.value = cleanedValue;
    
        const inputValue = {
            [e.target.name]: cleanedValue
        }
    
        setFormData((data) => ({
            ...data,
            ...inputValue,
        }));
    
        setError((data) => ({
            ...data,
            [e.target.name]: ""
        }));
    }    
 
    function handleKeyDown(e) {
        const { value } = e.target;
        const key = e.key;
    
        // Allow navigation keys
        if (["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(key)) {
            return;
        }
    
        // Prevent invalid characters (only allow digits and decimal point)
        if (!/^\d$/.test(key) && key !== '.') {
            e.preventDefault();
            return;
        }
    
        // Prevent more than one decimal point
        if (key === '.' && value.includes('.')) {
            e.preventDefault();
            return;
        }
    
        // Allow only one digit after the decimal point
        if (value.includes('.') && value.split('.')[1].length >= 1) {
            e.preventDefault();
            return;
        }
    
        // Validate numeric value between 0.1 and 0.9
        const newValue = parseFloat(value + key);
        if (newValue < 0.1 || newValue > 0.9) {
            e.preventDefault();
        }
        // setOpicty(newValue)
    }
    
    
    // console.log("opicity",opicity)

    



    const UploadImage = async (e, url) => {
        const files = e.target.files[0];

        // Check for file size (5MB = 5 * 1024 * 1024 bytes)
        const maxFileSize = 20 * 1024 * 1024; 
        if (files.size > maxFileSize) {
            toast.error("File size should not exceed 20MB"); 
            return;  
        }
        const validFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

        if (!validFormats.includes(files.type)) { 
            toast.error("Invalid file type. Please select an image file (jpg, jpeg, png, webp)."); 
            return;  
        }
        if(files){
            setFormData((data)=>({
                ...data,
                file: files,
                file_preview: URL.createObjectURL(files),
            }))
            setError((data)=>({
                ...data,
                file: "",
            }))
        }
        // const formData1 = new FormData();
        // formData1.append("file", files, files.name);
        // setUploadFileLoader(true);
        // const fileresponse = await UploadFile(accessToken, formData1);
        // if(fileresponse) {
        //     setFormData((data)=>({
        //         ...data,
        //         file: fileresponse,
        //     }))
        // };
        // e.target.value = null;
        // setUploadFileLoader(false);
    }
    const callRemoveSponsorsLogo = (e) =>{
        setFormData((data)=>({
            ...data,
            file:null,
            file_preview:null
        }))
    }
 
    const validation = () =>{
        let val = true;
        if(formData?.tile_size == ""){
            error.tile_size = "Enter tile size";
            val = false;
        }
       
        if (formData?.name === "") {
            error.name = "Enter mosaic name";
            val = false;
        }
    
        if(formData?.tile_size <= 4 ){
            error.tile_size = "Tile size should be greater than 5";
            val = false;
        }
        if(!is_mosaic && formData?.campaign_id == ""){
            error.campaign_id = "Please select campaign";
            val = false;
        }
        
        if(!formData?.file){
            error.file = "Please attach file";
            val = false;
        }
        if(formData?.opacity === "" && formData?.utilize_blending){
            error.opacity = "Please enter opacity";
            val = false;
        }
        if(formData?.opacity !== "" && formData?.opacity === "." && formData?.utilize_blending){
            error.opacity = "Please enter valid opacity";
            val = false;
        }
        // if (formData?.opacity !== "" && (parseFloat(formData?.opacity) < .1 || parseFloat(formData?.opacity) > .9)) {
        //     error.opacity = "Please enter valid opacity";
        //     val = false;
        // }
        // if(!is_mosaic && formData?.name){
        //     error.name = "Enter name";
        //     val = false;
        // }

        setError((data)=>({
            ...data,
            ...error,
        }))
        return val;
    }

    console.log("formData?.opacity",formData?.opacity)
    
    const onSubmit = async ( e ) =>{
        e.preventDefault();
        if(validation()){
            
            // setLoader(true)
            setLoad(true)
            const campaign = campaignOptionList?.find((item)=>item?.value == formData?.campaign_id);
            const data = new FormData();
            data.append("file", formData?.file);
            data.append("campaign_id", is_mosaic==="yes" ? parentCompaignObject?.id  : formData?.campaign_id);
            data.append("campaign", is_mosaic==="yes" ? parentCompaignObject?.slug  : campaign?.slug);
            data.append("tile_size", formData?.tile_size);
            data.append("is_high_quality",data?.quality?1:0)

            let URL = VALIDATE_MOSAIC_SOURCE_IMAGE;
            const response = await PostRequestAPI(URL, data, accessToken, true)
            // console.log("response", response)
            if(response?.status == 200){
                const oldData = {
                    ...formData,
                    data: response?.data?.data,
                    slug: campaign?.slug,
                    campaign_title: campaign?.title
                }
                setFormData(oldData)
                setShowModal(true);
                setLoad(false)

                // callCreateMosaic(oldData)
                // toast.success(response?.data?.msg);
                // navigate(MAIL_CAMPAIGN_MANAGEMENT, { replace: true })
            }else{
                if(typeof response?.data?.error == "string"){
                    toast.error(response?.data?.error);
                }
                setFormData((data)=>({
                    ...data,
                    data: null
                }))
                setLoad(false)

            }
            setLoader(false)
            setLoad(false)

        }else{
            error.opacity = "Please enter valid opacity";
        }
    }
    const callCreateMosaic = async (data, payload) =>{
        setLoader2(true)
        const data1 = new FormData();
        data1.append("file", data?.file);
        data1.append("campaign_id",is_mosaic==="yes" ? parentCompaignObject?.id  : data?.campaign_id);
        data1.append("campaign",is_mosaic==="yes" ? parentCompaignObject?.slug  : data?.slug);
        data1.append("tile_size", data?.tile_size);
        data1.append("is_test", data?.is_test ? 1 : 0);
        data1.append("name", formData?.name);
        data1.append("is_high_quality",data?.quality?1:0)
        data1.append("utilize_blending", formData?.utilize_blending ? 1:0);
        data1.append("utilize_less_occurrence", formData?.utilize_less_occurrence ? 1:0);
        data1.append("opacity", formData?.utilize_blending ? `0${formData?.opacity}` :null);
        const response = await PostRequestAPI(CREATE_MOSAIC_SOURCE_IMAGE, data1, accessToken, true)
        console.log("mosaic response",response)
        if(response?.status == 200){
            // callCreateMosaic(oldData)
            toast.success(response?.data?.msg);
            navigate(MOSAIC_MANAGEMENT, { replace: true })
            setLoader2(false)
        }else{
            if(typeof response?.data?.error == "string"){
                toast.error(response?.data?.error);
            }
            setFormData((data)=>({
                ...data,
                data: null
            }))
            setLoader2(false)
        }
        setLoader2(false)
    }

    return (
    <>
        <ul className="tabcomnsection">
            <li className={ "active text-capitalized"} >
                Create Mosaic
            </li>
            <div className="tabheadebuttns">
                <Button className="savchanges" onClick={onSubmit} disabled={load} >Save</Button>
                <Link className="btn savchanges linksmanage" to={MOSAIC_MANAGEMENT} >Back</Link>
            </div>

        </ul>
        <section className="formsectionset">
        <div className="tabcontent">
            <Scrollbars
                    style={{ height: "calc(100vh - 162px)" }} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                        <div className="formloginset formeditset">
                            <form onSubmit={onSubmit} className="row">
                        

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">{is_mosaic ==="yes" ? "Campaign":"Select Campaign"}</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                    {
                                        is_mosaic ==="yes" ? <FormGroup className="mb-3">
                                        <Form.Control 
                                                type="text" 
                                                value={parentCompaignObject?.title} 
                                                disabled
                                            />
                                        </FormGroup>:
                                    
                                        <Form.Group className="mb-3" >
                                            <Form.Select 
                                                type="text" 
                                                value={formData?.campaign_id} 
                                                onChange={onChange} 
                                                placeholder="Select campaign_id" 
                                                name='campaign_id'
                                                disabled={param?.campaign_mail_id && location?.state}
                                            >
                                                <option value={""} disabled >Select Campaign</option>
                                                {campaignOptionList?.map((item, index)=>(
                                                    <React.Fragment key={index}>
                                                        <option value={item?.value} >{item?.label}</option>
                                                    </React.Fragment>
                                                ))}
                                                {loding ?  <option>Loading ...</option>:null}
                                            </Form.Select>
                                            {error?.campaign_id && <div className="danger-colorset">{error?.campaign_id}</div>}
                                        </Form.Group>
                                        }
                                    </div>
                                </div>
                            
                                
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Upload Source Image</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="" >
                                            {!formData?.file && (
                                                <div className="uploadbuttons me-2 mb-2"
                                                 onClick={() => document.getElementById('fileInput')?.click()}
                                                >
                                                    <div className={UploadFileLoader ? "fileloader active" : "fileloader"}>
                                                        {UploadFileLoader ? 
                                                        (<Image src={LoaderImage} className="uploadImgIconse uploadimagload" alt="upload image" />) : 
                                                        (<>
                                                            <Image src={UploadImageIcon} className="uploadImgIconse" alt="upload image" />
                                                            <input type="file" id="fileInput" className="uploadfilInput" onChange={(e) => UploadImage(e, "SponsorsLogo")} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                                                        </>)}
                                                    </div>
                                                    <div className="uploadfilebox">
                                                        <Image src={DefaultImage} alt="upload image" />
                                                    </div>
                                                </div>
                                            )}
                                            {formData?.file_preview && (
                                                <React.Fragment>
                                                    <div className="imageuploads bg-grey me-2 mb-2">
                                                        <Image src={formData?.file_preview} alt="charity avatar" />
                                                        <span className="closeIcon" onClick={() => callRemoveSponsorsLogo()}></span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </Form.Group>
                                        {error?.file && <div className="danger-colorset">{error?.file}</div>}
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Tile Size</h4>
                                            <small>Minimum 5 and Maximum 100 tile size.</small>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <Form.Control 
                                                type="text" 
                                                value={formData?.tile_size} 
                                                onChange={onChange} 
                                                placeholder="Enter tile size" 
                                                name='tile_size'
                                            />
                                            {error?.tile_size && <div className="danger-colorset">{error?.tile_size}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-">Quality</h4>
                                                <small>High quality (maximum time consumption) / Low quality (minimum time consumption)</small>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch-quality"
                                                checked={formData?.quality?true:false}
                                                name='quality'
                                                onChange={(e)=>onChange({target:{name: "quality", type:"switch", value: e.target.checked}})}
                                            />
                                            <Badge bg={formData?.quality ? "primary" : "danger"}>{formData?.quality ? "High" : "Low"}</Badge>
                                        </Form>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-">Image repeatability</h4>
                                                <small>Mosaic creation with or without duplication of tile images.</small>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch-is_repeatability"
                                                checked={formData?.is_repeatability?true:false}
                                                name='is_repeatability'
                                                onChange={(e)=>onChange({target:{name: "is_repeatability", type:"switch", value: e.target.checked}})}
                                            />
                                            <Badge bg={formData?.is_repeatability ? "primary" : "danger"}>{formData?.is_repeatability ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Name</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <Form.Control 
                                                type="text" 
                                                value={formData?.name} 
                                                onChange={onChange} 
                                                placeholder="Enter name" 
                                                name='name'
                                                maxLength={100}
                                            />
                                            {error?.name && <div className="danger-colorset">{error?.name}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-">Utilize test images</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch-is_test"
                                                checked={formData?.is_test?true:false}
                                                name='is_test'
                                                onChange={(e)=>onChange({target:{name: "is_test", type:"switch", value: e.target.checked}})}
                                            />
                                            <Badge bg={formData?.is_test ? "primary" : "danger"}>{formData?.is_test ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-">Utilize Less Occurrence</h4>
                                            <small>To minimize the image repetition count.</small>

                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch-utilize_blending"
                                                checked={formData?.utilize_less_occurrence?true:false}
                                                name='utilize_less_occurrence'
                                                onChange={(e)=>onChange({target:{name: "utilize_less_occurrence", type:"switch", value: e.target.checked}})}
                                            />
                                            <Badge bg={formData?.utilize_less_occurrence ? "primary" : "danger"}>{formData?.utilize_less_occurrence                                                ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-">Utilize Blending</h4>
                                            <small>The image blending process may take up to 10 minutes to complete.</small>

                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form className="switchbtn">
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch-utilize_blending"
                                                checked={formData?.utilize_blending?true:false}
                                                name='utilize_blending'
                                                onChange={(e)=>onChange({target:{name: "utilize_blending", type:"switch", value: e.target.checked}})}
                                            />
                                            <Badge bg={formData?.utilize_blending ? "primary" : "danger"}>{formData?.utilize_blending ? "Yes" : "No"}</Badge>
                                        </Form>
                                    </div>
                                </div>

                                {
                                    formData?.utilize_blending &&
                                    <div className="row">
                                    <div className="col-md-4">
                                        <div className="formgroups">
                                            <h4 className="label-required">Opacity</h4>
                                            <small>Minimum .1 and Maximum <bold>.9</bold>  tile size.</small>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <Form.Group className="mb-3" >
                                            <Form.Control 
                                                type="text" 
                                                value={formData?.opacity}
                                                onKeyDown={handleKeyDown}  
                                                onChange={onChange}
                                                placeholder="Enter Opacity" 
                                                name='opacity'
                                                
                                            />
                                            {error?.opacity && <div className="danger-colorset">{error?.opacity}</div>}
                                        </Form.Group>
                                    </div>
                                    </div>
                                }
                        </form>
                    </div> 
                </Scrollbars>
            </div>
        </section>
        {ShowModal && (
            <Modal centered className='mosaic-modal' show onHide={()=>setShowModal()}>
                <Modal.Header className='bb-0' closeButton>
                    <h5 className='m-0'>Source Image Validation</h5>
                </Modal.Header>
                <Modal.Body>
                {loader2 && <CommonLoader classNameS={"charityLoader"} />}
                    <div className='d-flex w-100'>
                    {/* {formData?.file_preview && (
                        <React.Fragment>
                            <div className="imageuploads bg-grey me-2 mb-2">
                                <Image src={formData?.file_preview} alt="charity avatar" />
                            </div>
                        </React.Fragment>
                    )} */}
                    </div>
                    <h5>Result</h5>
                    <Table>
                        <tbody>
                            <tr className='bg-info bg-info-1'>
                                <th>Campaign Name</th>
                                <td> {is_mosaic==="yes" ? parentCompaignObject?.title : formData?.campaign_title}</td>
                            </tr>
                            <tr className='bg-info'>
                                <th>Available Input Count</th>
                                <td>{formData?.data?.available_input_count}</td>
                            </tr>
                            <tr className='bg-success'>
                                <th>No. of Tile Images Required</th>
                                <td>{formData?.data?.number_of_tile_images_required}</td>
                            </tr>
                            <tr className='bg-warning'>
                                <th>No. of Tiles per input tile</th>
                                <td>{formData?.data?.number_of_tiles_per_input_tile}</td>
                            </tr>
                            <tr className=' bg-info  bg-info-1'>
                                <th>Tile Size</th>
                                <td>{formData?.data?.tile_size}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='d-flex justify-content-end button-color '>
                        <Button disabled={loader2} onClick={()=>callCreateMosaic(formData)} className='savchanges btn-primary'>Create Mosaic</Button>
                    </div>
                </Modal.Body>
            </Modal>
        )}
        {loader && <CommonLoader classNameS={"charityLoader"}  />}
        </>
    )
}
